<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Ürün Bilgileri'"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @copyProduct="$emit('copyProduct')"
      @removeProduct="$emit('removeProduct', $event)"
    />

    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-overlay :show="show">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Dil"
                  :description="`Ürünü ${lang(
                    defaultDil
                  )} girmeye devam edin. Kaydettikten sonra dil güncellemelerini yapabilirsiniz.`"
                >
                  <v-select
                    v-model="defaultDil"
                    :options="diller"
                    :reduce="(diller) => diller.lang"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                    :disabled="!form.k_no"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Anasayfa Gösterim" description="Ürünün anasayfa vitrinde görünmesini istiyormusunuz">
                  <v-select
                    v-model="form.anasayfa"
                    :options="statuler"
                    :reduce="(statu) => statu.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider name="Kategori" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Kategori" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <treeselect
                      :options="kategoriler"
                      v-model="form.kategori_k_no"
                      :normalizer="normalizer"
                      :flat="true"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      placeholder="Lütfen Kategori Seçiniz"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      :class="{ 'is-invalid border-danger': !!validationContext.errors[0] }"
                      :multiple="true"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Bölge">
                  <v-select
                    v-model="form.bolge_k_no"
                    :options="bolgeler"
                    :reduce="(bolge) => bolge.k_no"
                    label="baslik"
                    :multiple="true"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Ürün Adı" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Ürün Adı">
                    <b-form-input
                      class="rounded-0"
                      ref="urun_adi"
                      v-model="form.icerik[defaultDil].urun_adi"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider name="Ön Açıklama" :rules="{ required: true, max: 160 }" v-slot="validationContext">
                  <b-form-group
                    :label="`Ön Açıklama ${onAciklamalength} / 160`"
                    description="Ön Açıklama 160 karakteri geçmemelidir"
                  >
                    <b-form-textarea
                      class="rounded-0"
                      ref="on_aciklama"
                      v-model="form.icerik[defaultDil].on_aciklama"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider name="Açıklama" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Açıklama" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <ckeditor
                      :editor="editor"
                      v-model="form.icerik[defaultDil].aciklama"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Program">
                  <ckeditor :editor="editor" v-model="form.icerik[defaultDil].program" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Tanıtım video ( Youtube Link )">
                  <b-form-input
                    class="rounded-0"
                    ref="urun_adi"
                    v-model="form.video"
                    placeholder="https://www.youtube.com/watch?v=2DpBke-woRc&list=RD2DpBke-woRc&start_radio=1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col cols="12" md="6" lg="6">
                <b-button squared block type="submit" variant="primary">
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ form.k_no == null ? 'Kaydet ve Devam et' : 'Güncelle' }}</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-button squared block>
                  <feather-icon icon="XSquareIcon" class="mr-50" />
                  <span class="align-middle">İptal</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </validation-observer>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EkleHeaders from '../component/ekleHeader.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import vSelect from 'vue-select';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  components: {
    VuePerfectScrollbar,
    EkleHeaders,
    vSelect,
    Treeselect,
  },
  props: {
    defaultDil: {
      type: String,
      required: true,
    },
    diller: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: false,
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  // mixins: [diller],
  data() {
    return {
      editor: ClassicEditor,
      show: false,
      statuler: [
        {
          id: true,
          title: 'Evet',
        },
        {
          id: false,
          title: 'Hayır',
        },
      ],

      sortValueBy: 'INDEX',

      normalizer(node) {
        return {
          id: node.key,
          label: node.name,
          children: node.subOptions,
        };
      },

      kategoriler: [],
      bolgeler: [],
      snowOption: {
        theme: 'snow',
        placeholder: 'Açıklama Yazınız...',
      },
    };
  },
  computed: {
    ...mapGetters(['getKategoriler', 'getBolgeler', 'getUrun']),
    onAciklamalength() {
      return this.form.on_aciklama != null ? this.form.on_aciklama.length : 0;
    },
    lang() {
      return (dil) => this.diller.find((x) => x.lang === dil).title;
    },
  },
  created() {
    this.$emit('refreshData', true);
    this.handlerGetAllData();
  },
  methods: {
    async handlerGetAllData() {
      this.show = true;
      this.kategoriler = [];
      this.bolgeler = [];

      await this.$store.dispatch('kategoriListele');
      await this.$store.dispatch('bolgeListele');

      const kategoriler = await this.getKategoriler;
      this.bolgeler = this.getBolgeler;

      kategoriler.forEach((el) => {
        if (el.kategori_k_no == null || el.kategori_k_no == '') {
          this.kategoriler.push({ key: el.k_no, k_no: el.k_no, name: el.icerik['tr'].baslik, subOptions: [] });
        }
      });

      kategoriler.forEach((el) => {
        if (el.kategori_k_no != null) {
          let index = this.kategoriler.findIndex((x) => x.k_no == el.kategori_k_no);
          if (index != -1) {
            this.kategoriler[index].subOptions.push({ key: el.k_no, k_no: el.k_no, name: el.icerik['tr'].baslik });
          }
        }
      });

      this.show = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.$emit('onSubmit');
    },
    removeProduct(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.vue-treeselect__control {
  border: 1px solid #d8d6dd;
  border-radius: 0px !important;
  height: 38px;
}
.quill-editor {
  padding-bottom: 70px;
  @media only screen and (max-width: 600px) {
    padding-bottom: 90px;
  }
  .ql-toolbar,
  .ql-container {
    border-radius: 0 !important;
  }
}
</style>
